import { Seconds } from "@/ts/util/units";
import { getTimeSeconds } from "@/ts/util/utils";


class MemoCacheEntry<V> {
    readonly dependencies: any[];
    readonly value: V;
    lastUse: Seconds;

    constructor(dependencies: any[], value: V) {
        this.dependencies = dependencies;
        this.value = value;
        this.lastUse = getTimeSeconds();
    }

    markUse() {
        this.lastUse = getTimeSeconds();
    }

    areDependenciesEqual(dependencies: any[]) {
        if (this.dependencies.length !== dependencies.length)
            return false;

        for (let index = 0; index < dependencies.length; ++index) {
            if (this.dependencies[index] !== dependencies[index])
                return false;
        }
        return true;
    }
}


/**
 * Caches the result of computations, to avoid re-calculating expensive operations.
 * Effectively, this trades compute for memory. This should only be used for
 * expensive operations.
 */
export class MemoCache<V> {
    private readonly name: string;
    private readonly capacity: number;
    private readonly entries: MemoCacheEntry<V>[];

    cacheHits: number = 0;
    cacheMisses: number = 0;

    constructor(name: string, capacity: number) {
        this.name = name;
        this.capacity = capacity;
        this.entries = [];
    }

    private checkCacheHealth() {
        const missRatio = this.cacheMisses / (this.cacheHits + this.cacheMisses);
        if (missRatio > 0.5) {
            console.warn(
                "High cache miss ratio for " + this.name
                + " - Hits: " + this.cacheHits + ", Misses: " + this.cacheMisses + ". "
                + "Consider removing the use of cache, or changing the dependencies.",
            );
        }
        this.cacheHits = 0;
        this.cacheMisses = 0;
    }

    private findEntry(dependencies: any[]): MemoCacheEntry<V> | null {
        for (const entry of this.entries) {
            if (entry.areDependenciesEqual(dependencies))
                return entry;
        }
        return null;
    }

    private discardOldestEntry() {
        const time = getTimeSeconds();
        let oldestIndex = null;
        let oldestAge = null;
        for (let index = 0; index < this.entries.length; ++index) {
            const entry = this.entries[index];
            const age = time - entry.lastUse;
            if (oldestAge === null || age > oldestAge) {
                oldestIndex = index;
                oldestAge = age;
            }
        }
        if (oldestIndex === null)
            return;

        this.entries.splice(oldestIndex, 1);
    }

    getOrCompute(computeFn: () => V, dependencies: any[]): V {
        try {
            const existingEntry = this.findEntry(dependencies);
            if (existingEntry !== null) {
                existingEntry.markUse();
                this.cacheHits += 1;
                return existingEntry.value;
            }

            // Do the computation.
            const value = computeFn();
            this.cacheMisses += 1;

            // Store the result.
            if (this.entries.length >= this.capacity) {
                this.discardOldestEntry();
            }
            this.entries.push(new MemoCacheEntry<V>(dependencies, value));
            return value;
        } finally {
            if (this.cacheMisses + this.cacheHits >= 1000) {
                this.checkCacheHealth();
            }
        }
    }
}
