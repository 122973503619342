import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { useRef } from "react";
import { Rune } from "@/ts/business/Rune";


export class SharedRendererState {
    highlightIntroducedPiece: PlayerType | null = null;
    currentMoveIndex: Rune<number> = new Rune<number>(-1);
    isAnimatingQuickMoves: Rune<boolean> = new Rune<boolean>(false);
}


export function useCreateSharedRendererState(): SharedRendererState {
    const sharedRendererStateRef = useRef<SharedRendererState | null>(null);
    if (sharedRendererStateRef.current === null) {
        sharedRendererStateRef.current = new SharedRendererState();
    }

    const sharedRendererState = sharedRendererStateRef.current;
    if (!sharedRendererState)
        throw new Error("Missing shared renderer state");

    return sharedRendererState;
}
