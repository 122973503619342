import { Milliseconds, Seconds } from "@/ts/util/units";
import { getTimeSeconds, LONG_TIME_AGO } from "@/ts/util/utils";


/**
 * Whether a fade is fading/faded in, or fading/faded out.
 */
export enum FadeDirection {
    OUT = 1,
    IN = 2,
}

export function fadeInIf(fadeIn: boolean): FadeDirection {
    return fadeIn ? FadeDirection.IN : FadeDirection.OUT;
}


/**
 * Allows controlling animations based on linearly interpolating between 0 and 1.
 */
export class Fade {
    protected readonly defaultInDuration: number;
    protected readonly defaultOutDuration: number;

    protected direction: FadeDirection = FadeDirection.OUT;
    protected start: Seconds = LONG_TIME_AGO;
    protected duration: Seconds = -1;

    constructor(
        defaultInDuration?: Seconds,
        defaultOutDuration?: Seconds,
    ) {
        if (defaultInDuration === undefined) {
            defaultInDuration = -1;
        }
        if (defaultOutDuration === undefined) {
            defaultOutDuration = defaultInDuration;
        }
        this.defaultInDuration = defaultInDuration;
        this.defaultOutDuration = defaultOutDuration;
    }

    getRaw0To1(): number {
        const time = getTimeSeconds();
        if (time >= this.start + this.duration)
            return 1;
        if (time <= this.start)
            return 0;
        return (time - this.start) / this.duration;
    }

    get(): number {
        const raw = this.getRaw0To1();
        return this.direction === FadeDirection.IN ? raw : 1 - raw;
    }

    getEaseInOut(): number {
        const x = this.get();
        if (x === 0 || x === 1)
            return x;

        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    }

    isFadeIn(): boolean {
        return this.direction == FadeDirection.IN;
    }

    isFadeOut(): boolean {
        return this.direction == FadeDirection.OUT;
    }

    fade(
        direction: FadeDirection,
        duration?: Milliseconds,
        fromStart?: boolean,
    ): this {
        if (duration === undefined) {
            if (direction === FadeDirection.IN) {
                duration = this.defaultInDuration;
            } else {
                duration = this.defaultOutDuration;
            }
        }

        const currentValue = this.get();
        this.start = getTimeSeconds();
        this.direction = direction;
        this.duration = duration;

        // Correct the start time so the get() value never jumps.
        if (!fromStart) {
            if (direction == FadeDirection.IN) {
                this.start -= currentValue * this.duration;
            } else {
                this.start -= (1 - currentValue) * this.duration;
            }
        }
        return this;
    }

    fadeInOrOut(fadeIn: boolean, duration?: Milliseconds): this {
        return this.fade(fadeInIf(fadeIn), duration);
    }

    fadeIn(duration?: Milliseconds): this {
        return this.fade(FadeDirection.IN, duration);
    }

    fadeOut(duration?: Milliseconds): this {
        return this.fade(FadeDirection.OUT, duration);
    }

    visible(): this {
        return this.fadeIn(0);
    }

    invisible(): this {
        return this.fadeOut(0);
    }
}

/**
 * An asymmetric fade which fades in, waits, and then fades out.
 */
export class StagedFade extends Fade {
    protected readonly inDuration: Milliseconds;
    protected readonly stayDuration: Milliseconds;
    protected readonly outDuration: Milliseconds;
    protected readonly inRatio: number;
    protected readonly stayRatio: number;
    protected readonly outRatio: number;

    constructor(
        inDuration: Milliseconds,
        stayDuration: Milliseconds,
        outDuration: Milliseconds,
    ) {
        super(inDuration + stayDuration + outDuration, outDuration);
        this.inDuration = inDuration;
        this.stayDuration = stayDuration;
        this.outDuration = outDuration;
        this.inRatio = inDuration / this.defaultInDuration;
        this.stayRatio = stayDuration / this.defaultInDuration;
        this.outRatio = outDuration / this.defaultInDuration;
    }

    get(): number {
        const value = super.get();
        if (super.isFadeOut())
            return value;
        if (value <= this.inRatio)
            return value / this.inRatio;
        if (value <= this.inRatio + this.stayRatio)
            return 1;
        return (1 - value) / this.outRatio;
    }

    isFadeIn(): boolean {
        if (this.isFadeOut())
            return false;
        return super.get() <= this.inDuration + this.stayDuration;
    }

    isFadeOut(): boolean {
        return !this.isFadeIn();
    }

    fade(
        direction: FadeDirection,
        duration?: Milliseconds,
        fromStart?: boolean,
    ): this {
        const currentValue = this.get();
        super.fade(direction, duration, fromStart);

        // Correct the start time so that the fades line up.
        if (!fromStart) {
            if (direction == FadeDirection.IN) {
                this.start += currentValue * this.inDuration;
            } else {
                this.start -= (1 - currentValue) * this.outDuration;
            }
        }
        return this;
    }
}
