import { motion } from "framer-motion";
import { ReactNode } from "react";
import { fadeInOut, FadeInOutOptions } from "@/app_util/fadeInOut";


interface FadingDivProps {
    children: ReactNode;
    options?: FadeInOutOptions;
    [prop: string]: unknown;
}

export function FadingDiv({ children, options, ...props }: FadingDivProps) {
    return (
        <motion.div
            {...fadeInOut({
                duration: 0.5,
                ...options,
            })}
            {...props}>

            {children}
        </motion.div>
    );
}
