import { MutableRefObject, useLayoutEffect, useState } from "react";

export function useBounds(targetRef: MutableRefObject<HTMLElement | null>): DOMRect | null {
    const [bounds, setBounds] = useState<DOMRect | null>(null);

    useLayoutEffect(() => {
        const recalc = () => {
            const elem = targetRef.current;
            setBounds(elem?.getBoundingClientRect() ?? null);
        };
        recalc();

        let resizeObserver: ResizeObserver | null = null;
        if (window.ResizeObserver) {
            resizeObserver = new ResizeObserver(recalc);

            const elem = targetRef.current;
            if (elem) {
                resizeObserver.observe(elem);
            }
        }

        window.addEventListener("load", recalc);
        window.addEventListener("resize", recalc);

        return () => {
            resizeObserver?.disconnect();
            window.removeEventListener("load", recalc);
            window.removeEventListener("resize", recalc);
        };
    }, [targetRef]);

    return bounds;
}
