import { ImageAsset } from "@/app_components/assets/ImageAsset";
import { Vec2 } from "@/ts/util/Vec2";
import { clamp } from "@/ts/util/numbers";


export function rgb(r: number, g?: number, b?: number): string {
    if (g === undefined) {
        g = r;
        b = r;
    }
    if (b === undefined)
        throw new Error("If g is provided, b must be as well");

    r = Math.round(r);
    g = Math.round(g);
    b = Math.round(b);
    return "rgba(" + r + ", " + g + ", " + b + ")";
}


export function rgba(r: number, g?: number, b?: number, a?: number): string {
    if (b === undefined) {
        a = g;
        g = r;
        b = r;
    }
    if (a === undefined) {
        a = 1.0;
    }
    if (g === undefined)
        throw new Error("g is unexpectedly undefined");

    r = Math.round(r);
    g = Math.round(g);
    b = Math.round(b);
    return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
}

export function drawCircularShadow(
    ctx: CanvasRenderingContext2D,
    x: number, y: number, radius: number,
    r?: number, g?: number, b?: number,
    a?: number,
) {
    if (r === undefined) {
        r = 0;
        g = 0;
        b = 0;
    } else if (g === undefined) {
        g = r;
        b = r;
    }
    if (a === undefined) {
        a = 1.0;
    }
    if (b === undefined)
        throw new Error("Cannot only provide red and green, without blue");

    ctx.save();
    try {
        const gradient = ctx.createRadialGradient(
            x, y, radius * 0.75, x, y, radius * 1.3,
        );
        gradient.addColorStop(0, rgba(r, g, b, clamp(a, 0, 1)));
        gradient.addColorStop(0.33, rgba(r, g, b, clamp(a * 0.7, 0, 1)));
        gradient.addColorStop(0.66, rgba(r, g, b, clamp(a * 0.4, 0, 1)));
        gradient.addColorStop(1, rgba(r, g, b, 0));

        ctx.fillStyle = gradient;

        ctx.beginPath();
        ctx.arc(x, y, radius * 1.5, 0, 2 * Math.PI);
        ctx.fill();
    } finally {
        ctx.restore();
    }
}

export function drawPiece(
    ctx: CanvasRenderingContext2D,
    pieceImage: ImageAsset,
    centre: Vec2,
    width: number,
) {
    const left = centre.x - width / 2;
    const top = centre.y - width / 2;
    pieceImage.draw(ctx, left, top, width, width);
}
