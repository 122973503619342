import { MoveCategory } from "@/ts/business/analysis/MoveCategory";
import { getMoveCategoryIcon } from "@/app_components/icon/analysis/getMoveCategoryIcon";
import Image from "next/image";


interface MoveCategoryIconProps {
    moveCategory: MoveCategory;
    className?: string;
}


export function MoveCategoryIcon({ moveCategory, className }: MoveCategoryIconProps) {
    const icon = getMoveCategoryIcon(moveCategory);
    return (
        <Image
            className={className}
            src={icon}
            alt={moveCategory.name} />
    );
}
