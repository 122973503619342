import { Vec2 } from "@/ts/util/Vec2";
import { MutableRefObject, useEffect, useMemo } from "react";
import { Seconds, styleToScreenPx } from "@/ts/util/units";
import { getTimeSeconds } from "@/ts/util/utils";
import { max } from "@/ts/util/numbers";

/**
 * Stores the state of the mouse at any one point in time.
 */
export class MouseState {
    public static readonly NONE = new MouseState(null, false, false, null, null);

    readonly location: Vec2 | null;
    readonly isTouch: boolean;
    readonly mouseDown: boolean;
    readonly mouseDownTime: Seconds | null;
    readonly mouseDownLocation: Vec2 | null;

    constructor(
        location: Vec2 | null,
        isTouch: boolean,
        mouseDown: boolean,
        mouseDownTime: Seconds | null,
        mouseDownLocation: Vec2 | null,
    ) {
        this.location = location;
        this.isTouch = isTouch;
        this.mouseDown = mouseDown;
        this.mouseDownTime = mouseDownTime;
        this.mouseDownLocation = mouseDownLocation;
    }

    withNewLocation(location: Vec2 | null) {
        return new MouseState(
            location,
            this.isTouch,
            this.mouseDown,
            this.mouseDownTime,
            this.mouseDownLocation,
        );
    }

    equals(other: MouseState) {
        return Vec2.areEqual(this.location, other.location)
            && this.isTouch === other.isTouch
            && this.mouseDown === other.mouseDown
            && this.mouseDownTime === other.mouseDownTime
            && Vec2.areEqual(this.mouseDownLocation, other.mouseDownLocation);
    }
}

/**
 * Listens to mouse and touch events, and forwards them to component renderers.
 */
export class MouseListener {
    static readonly HOVER_CLEAR_DURATION: Seconds = 0.2;

    private readonly stateChangeListeners: ((newState: MouseState, oldState: MouseState) => void)[];
    private readonly mouseDownListeners: ((state: MouseState) => boolean)[];
    private readonly mouseReleaseListeners: ((state: MouseState) => boolean)[];
    private readonly mouseMoveListeners: ((state: MouseState) => boolean)[];

    private currentState: MouseState;
    private mouseEventsIgnoreFrames: number = 0;
    private mouseEventsIgnoredPreventDefault: boolean = false;
    private hoverClearTime: Seconds | null = null;

    constructor() {
        this.stateChangeListeners = [];
        this.mouseDownListeners = [];
        this.mouseReleaseListeners = [];
        this.mouseMoveListeners = [];
        this.currentState = MouseState.NONE;
    }

    private updateMouseState(state: MouseState) {
        const oldState = this.currentState;
        this.currentState = state;
        if (!state.equals(oldState)) {
            this.invokeStateChangeListeners(state, oldState);
        }
    }

    /**
     * To avoid the simulated mouse events on mobile, we use flags that
     * we have to update each frame to keep track of events that should be
     * ignored.
     */
    processFrame() {
        this.mouseEventsIgnoreFrames = max(0, this.mouseEventsIgnoreFrames - 1);

        // Check if we should clear the hover position.
        if (this.hoverClearTime !== null && !this.currentState.mouseDown && this.currentState.isTouch) {
            const timeToHoverClear = this.hoverClearTime - getTimeSeconds();
            if (timeToHoverClear < 0) {
                this.clearHover();
            }
        }
    }

    private startIgnoringMouseEvents(preventDefault: boolean) {
        this.mouseEventsIgnoreFrames = 2;
        this.mouseEventsIgnoredPreventDefault = preventDefault;
    }

    private processIgnoredMouseEvents(event: MouseEvent): boolean {
        const ignored = (this.mouseEventsIgnoreFrames > 0);
        if (this.mouseEventsIgnoredPreventDefault) {
            event.preventDefault();
        }
        return ignored;
    }

    clearHover() {
        const state = this.currentState;
        const newState = new MouseState(
            null, state.isTouch, state.mouseDown,
            state.mouseDownTime, state.mouseDownLocation,
        );
        this.removeHoverClear();
        this.updateMouseState(newState);
    }

    private removeHoverClear() {
        this.hoverClearTime = null;
    }

    private resetHoverClear() {
        this.hoverClearTime = getTimeSeconds() + MouseListener.HOVER_CLEAR_DURATION;
    }

    getMouseState(): MouseState {
        return this.currentState;
    }

    addStateChangeListener(listener: (newState: MouseState, oldState: MouseState) => void) {
        if (!this.stateChangeListeners.includes(listener)) {
            this.stateChangeListeners.push(listener);
        }
    }

    removeStateChangeListener(listener: (newState: MouseState, oldState: MouseState) => void) {
        const index = this.stateChangeListeners.indexOf(listener);
        if (index >= 0) {
            this.stateChangeListeners.splice(index, 1);
        }
    }

    private invokeStateChangeListeners(newState: MouseState, oldState: MouseState) {
        for (const listener of this.stateChangeListeners) {
            listener(newState, oldState);
        }
    }

    addMouseMoveListener(listener: (state: MouseState) => boolean) {
        if (!this.mouseMoveListeners.includes(listener)) {
            this.mouseMoveListeners.push(listener);
        }
    }

    removeMouseMoveListener(listener: (state: MouseState) => boolean) {
        const index = this.mouseMoveListeners.indexOf(listener);
        if (index >= 0) {
            this.mouseMoveListeners.splice(index, 1);
        }
    }

    private invokeMouseMoveListeners(state: MouseState): boolean {
        let preventDefault = false;
        for (const listener of this.mouseMoveListeners) {
            preventDefault = listener(state) || preventDefault;
        }
        return preventDefault;
    }

    addMouseDownListener(listener: (state: MouseState) => boolean) {
        if (!this.mouseDownListeners.includes(listener)) {
            this.mouseDownListeners.push(listener);
        }
    }

    removeMouseDownListener(listener: (state: MouseState) => boolean) {
        const index = this.mouseDownListeners.indexOf(listener);
        if (index >= 0) {
            this.mouseDownListeners.splice(index, 1);
        }
    }

    private invokeMouseDownListeners(state: MouseState): boolean {
        let preventDefault = false;
        for (const listener of this.mouseDownListeners) {
            preventDefault = listener(state) || preventDefault;
        }
        return preventDefault;
    }

    addMouseReleaseListener(listener: (state: MouseState) => boolean) {
        if (!this.mouseReleaseListeners.includes(listener)) {
            this.mouseReleaseListeners.push(listener);
        }
    }

    removeMouseReleaseListener(listener: (state: MouseState) => boolean) {
        const index = this.mouseReleaseListeners.indexOf(listener);
        if (index >= 0) {
            this.mouseReleaseListeners.splice(index, 1);
        }
    }

    private invokeMouseReleaseListeners(state: MouseState): boolean {
        let preventDefault = false;
        for (const listener of this.mouseReleaseListeners) {
            preventDefault = listener(state) || preventDefault;
        }
        return preventDefault;
    }

    static getMouseEventLocation(event: MouseEvent) {
        const bounds = (event.currentTarget as HTMLElement).getBoundingClientRect();
        return new Vec2(
            styleToScreenPx(event.clientX - bounds.left),
            styleToScreenPx(event.clientY - bounds.top),
        );
    }

    static getTouchEventLocation(event: TouchEvent) {
        if (event.touches.length !== 1)
            throw new Error("Expected a single touch");

        const bounds = (event.currentTarget as HTMLElement).getBoundingClientRect();
        const touch = event.touches[0];
        return new Vec2(
            styleToScreenPx(touch.clientX - bounds.left),
            styleToScreenPx(touch.clientY - bounds.top),
        );
    }

    onMouseMove(event: MouseEvent) {
        if (this.processIgnoredMouseEvents(event))
            return;

        const location = MouseListener.getMouseEventLocation(event);
        const state = this.currentState.withNewLocation(location);
        this.updateMouseState(state);
        this.removeHoverClear();

        if (this.invokeMouseMoveListeners(state)) {
            event.preventDefault();
        }
    }

    onMouseDown(event: MouseEvent) {
        if (this.processIgnoredMouseEvents(event))
            return;

        const location = MouseListener.getMouseEventLocation(event);
        const state = new MouseState(location, false, true, getTimeSeconds(), location);
        this.updateMouseState(state);
        this.removeHoverClear();

        if (this.invokeMouseDownListeners(state)) {
            event.preventDefault();
        }
    }

    onMouseUp(event: MouseEvent) {
        if (this.processIgnoredMouseEvents(event))
            return;

        const location = MouseListener.getMouseEventLocation(event);
        const currentState = this.currentState;
        const state = new MouseState(
            location, false, false,
            currentState.mouseDownTime,
            currentState.mouseDownLocation,
        );
        this.updateMouseState(state);
        this.removeHoverClear();

        if (this.invokeMouseReleaseListeners(state)) {
            event.preventDefault();
        }
    }

    onTouchMove(event: TouchEvent) {
        let preventDefault = false;

        if (event.touches.length === 1) {
            const location = MouseListener.getTouchEventLocation(event);
            const state = this.currentState.withNewLocation(location);
            this.updateMouseState(state);
            this.removeHoverClear();

            if (this.invokeMouseMoveListeners(state)) {
                event.preventDefault();
                preventDefault = true;
            }
        } else {
            this.updateMouseState(MouseState.NONE);
        }
        this.startIgnoringMouseEvents(preventDefault);
    }

    onTouchStart(event: TouchEvent) {
        let preventDefault = false;

        if (event.touches.length === 1) {
            const location = MouseListener.getTouchEventLocation(event);
            const state = new MouseState(location, true, true, getTimeSeconds(), location);
            this.updateMouseState(state);
            this.removeHoverClear();

            if (this.invokeMouseDownListeners(state)) {
                event.preventDefault();
                preventDefault = true;
            }
        } else {
            this.updateMouseState(MouseState.NONE);
        }
        this.startIgnoringMouseEvents(preventDefault);
    }

    onTouchEnd(event: TouchEvent) {
        let preventDefault = false;

        if (this.currentState.mouseDown) {
            const location = this.currentState.location;
            const currentState = this.currentState;
            const state = new MouseState(
                location, true, false,
                currentState.mouseDownTime,
                currentState.mouseDownLocation,
            );
            this.updateMouseState(state);
            this.resetHoverClear();

            if (this.invokeMouseReleaseListeners(state)) {
                event.preventDefault();
                preventDefault = true;
            }
        } else {
            this.updateMouseState(MouseState.NONE);
        }
        this.startIgnoringMouseEvents(preventDefault);
    }
}

/**
 * Returns a function that gets the current state of the mouse
 * in relation to the given element.
 */
export function useMouseListener(
    elementRef: MutableRefObject<HTMLElement | null>,
): MouseListener {
    const listener = useMemo(() => new MouseListener(), []);

    useEffect(() => {
        const element = elementRef.current;
        if (!element)
            return;

        let animationFrameID = -1;
        const processFrame = () => {
            listener.processFrame();
            animationFrameID = requestAnimationFrame(processFrame);
        };

        const onMouseMove = (event: MouseEvent) => listener.onMouseMove(event);
        const onMouseDown = (event: MouseEvent) => listener.onMouseDown(event);
        const onMouseUp = (event: MouseEvent) => listener.onMouseUp(event);
        const onTouchMove = (event: TouchEvent) => listener.onTouchMove(event);
        const onTouchStart = (event: TouchEvent) => listener.onTouchStart(event);
        const onTouchEnd = (event: TouchEvent) => listener.onTouchEnd(event);

        processFrame();
        element.addEventListener("mousemove", onMouseMove);
        element.addEventListener("mousedown", onMouseDown);
        element.addEventListener("mouseup", onMouseUp);
        element.addEventListener("touchmove", onTouchMove);
        element.addEventListener("touchstart", onTouchStart);
        element.addEventListener("touchend", onTouchEnd);

        return () => {
            cancelAnimationFrame(animationFrameID);
            element.removeEventListener("mousemove", onMouseMove);
            element.removeEventListener("mousedown", onMouseDown);
            element.removeEventListener("mouseup", onMouseUp);
            element.removeEventListener("touchmove", onTouchMove);
            element.removeEventListener("touchstart", onTouchStart);
            element.removeEventListener("touchend", onTouchEnd);
        };
    }, [elementRef, listener]);

    return listener;
}
